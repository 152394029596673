import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import Seo from "../components/StaticPages/Seo";
import { CITIES, SEODATA } from "../constants/homePageConstants";
import { getLocationFromLocalStorage, setLocationInLocalStorage } from "../util/helperFunctions";
import { homepageApi } from "../services/homepage";
import Layout from "../layouts/StaticLayout";
import { getSession } from "../lib/getSession";
import { redisClient } from "../db/redis";
import HeroSectionHome from "../components/HeroSection/HeroSection";
import VenueListing from "../components/Common/VenueListing";
import { UseStateValue } from "../state";
import { IO_API_SERVICE } from "../services/ioApi";
import ActivityList from "../components/Common/ActivityListing";
import PopularSports from "../components/Home/PopularSports";
import PartnerWithUs from "../components/Home/PartnerWithUs";
import AboutUsHome from "../components/AboutUsHome";

// Loader components
import PromotionBanner from "../components/Home/PromotionBanner";
import Blogs from "../components/Common/Blogs";
import SpotLight from "../components/Home/SpotLight";
import MarketingBanner from "../components/Home/MarketingBanner";
import { currentLocation } from "../util/findLocation";

export default function Home() {
   const [{ allSports, location }, dispatch] = UseStateValue();
   const [latlng, setLatlng] = useState();

   const [homePageData, setHomePageData] = useState({
      banner: [],
      marketingSpotlight: {}
   });

   // Fetch location from local storage
   const storedLocation = getLocationFromLocalStorage();

   // Fetch marketing Bannering and marketing spotlight section
   useEffect(() => {
      if (latlng) {
         let body = {
            lat: latlng.lat,
            lng: latlng.lng,
            countryCode: "%2b91"
         };
         IO_API_SERVICE.getHomePageBanners(body).then(res => {
            if (res.data.requestStatus === 1) {
               const banner = res?.data?.data?.banner;
               const marketingSpotlight = res?.data?.data?.marketingSpotlight;
               setHomePageData({
                  banner,
                  marketingSpotlight
               });
            }
         });
      }
   }, [latlng]);

   // fetch all sports
   useEffect(() => {
      if (!allSports?.list?.length > 0) {
         dispatch({
            type: "SWITCH_LOADER",
            payload: true
         });
         console.log("sports not available");

         IO_API_SERVICE.fetchAllSports().then(response => {
            dispatch({
               type: "SET_ALL_SPORTS",
               payload: response
            });
            dispatch({
               type: "SWITCH_LOADER",
               payload: false
            });
         });
      }
   }, []);

   // Fetch Location
   useEffect(() => {

      if (!storedLocation) {

         console.log('=====> loading');
         let defaultLocation = { city: "bangalore", lat: 12.9716, lng: 77.5946 };
         
         console.log('=====> default', { defaultLocation });
         
         dispatch({ type: "LAT_LONG", payload: [defaultLocation.lat, defaultLocation.lng] });
         
         dispatch({
            type: "CURRENT_LOCATION",
            payload: defaultLocation.city
         });

         setLatlng({ lat: defaultLocation.lat, lng: defaultLocation.lng });
         setLocationInLocalStorage(defaultLocation.city, defaultLocation.lat, defaultLocation.lng);
         
         // homepageApi.getLocationFromIp()
         //    .then(res => {
         //       console.log('=====> loading 123', JSON.stringify(res.data, null, 2));

         //       if (res.data?.success) {
         //          const { latitude, longitude, city } = res.data;

         //          console.log('=====> success', { latitude, longitude, city });
      
         //          const lat = latitude;
         //          const lng = longitude;

         //          dispatch({ type: "LAT_LONG", payload: [lat, lng] });
                  
         //          dispatch({
         //             type: "CURRENT_LOCATION",
         //             payload: city
         //          });

         //          setLatlng({ lat, lng });

         //          setLocationInLocalStorage(city, lat, lng);
         //       } else {
         //          // Set default location to bangalore
         //          let defaultLocation = { city: "bangalore", lat: 12.9716, lng: 77.5946 };
         
         //          console.log('=====> default', { defaultLocation });
                  
         //          dispatch({ type: "LAT_LONG", payload: [defaultLocation.lat, defaultLocation.lng] });
                  
         //          dispatch({
         //             type: "CURRENT_LOCATION",
         //             payload: defaultLocation.city
         //          });

         //          setLatlng({ lat: defaultLocation.lat, lng: defaultLocation.lng });
         //          setLocationInLocalStorage(defaultLocation.city, defaultLocation.lat, defaultLocation.lng);
         //       }
         //    }).catch(error => {
         //       console.error('Error: ', error);

         //       let defaultLocation = { city: "bangalore", lat: 12.9716, lng: 77.5946 };
         
         //       console.log('=====> error', { defaultLocation });

         //       dispatch({ type: "LAT_LONG", payload: [defaultLocation.lat, defaultLocation.lng] });
               
         //       dispatch({
         //          type: "CURRENT_LOCATION",
         //          payload: defaultLocation.city
         //       });
         //       setLatlng({ lat: defaultLocation.lat, lng: defaultLocation.lng });
         //       setLocationInLocalStorage(defaultLocation.city, defaultLocation.lat, defaultLocation.lng);
         //    });

         async function currentLoc() {
            const location = await currentLocation(dispatch);
   
            const script = document.getElementById("googleMapsScript");
            if (!script) {
               const newScript = document.createElement("script");
               newScript.id = "googleMapsScript";
               newScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyB9q4uF6xjrDG-n2jvClxrtOV_jSXUAPUY&libraries=places&callback=initMap";
               newScript.async = true;
               newScript.defer = true;
               document.body.appendChild(newScript);
            }
  
            console.log('=====> success 12345678');
            
            if (location) {
               const { lat, long } = location;
               IO_API_SERVICE.getAddress(lat, long)
                  .then(response => {
      
                     let results = response?.data;
      
                     let sublocality = "";
                     console.log('=====> success =====>');
      
                     results.results[0].address_components.forEach(result => {
                        result.types.forEach(locality => {
                           if (locality == "locality" && result.long_name !== "[no name]" && !sublocality) {
                              sublocality = result.long_name;
                           }
                           if (locality === 'sublocality_level_1' && result.long_name !== "[no name]") {
                              sublocality = result.long_name;
                              return true;
                           }
                        });
                        console.log('=====> success sublocality');
      
                        if (sublocality) {
                           return true;
                        }
                     });
      
                     dispatch({ type: "LAT_LONG", payload: [lat, long] });
                        
                     dispatch({
                        type: "CURRENT_LOCATION",
                        payload: sublocality
                     });
      
                     setLatlng({ lat, long });
      
                     setLocationInLocalStorage(sublocality, lat, long);
                  })
                  .catch(err => {
                     dispatch({
                        type: "SWITCH_LOADER",
                        payload: false
                     });
                     let defaultLocation = { city: "bangalore", lat: 12.9716, lng: 77.5946 };
               
                     console.log('=====> success 12345678 SWITCH_LOADER');
   
                     dispatch({ type: "LAT_LONG", payload: [defaultLocation.lat, defaultLocation.lng] });
                     
                     dispatch({
                        type: "CURRENT_LOCATION",
                        payload: defaultLocation.city
                     });
   
                     setLatlng({ lat: defaultLocation.lat, lng: defaultLocation.lng });
                     setLocationInLocalStorage(defaultLocation.city, defaultLocation.lat, defaultLocation.lng);
                     console.log({ err });
                  });
            }
         }

         currentLoc();         
      } else {
         setLatlng({ lat: storedLocation?.lat, lng: storedLocation?.lng });
      }
   }, []);

   useEffect(() => {
      console.log('======> ', { location });
      if (location && location.locationLatLon && !isEmpty(location.locationLatLon)) {
         const lat = location?.locationLatLon[0];
         const lng = location?.locationLatLon[1];
         setLatlng({ lat, lng });
         console.log('=====> 12345678900987654321');
         
      }
   }, [location?.locationLatLon]);

   return (
      <div className="flex flex-col w-full scroll-smooth">
         {/* Seo Details */}
         <Seo {...SEODATA} />

         {/* Hero Section */}
         <HeroSectionHome />

         {/* Marketing Segment banners */}
         {homePageData?.banner?.length ? <section className="mt-8">
            <MarketingBanner banners={homePageData.banner} />
         </section> : null}

         <section className="md:mx-12 mx-4 md:mt-14 mt-8 bg-white md:rounded-3xl rounded-2xl md:pt-12 pt-6 xxl:mx-auto max-w-page min-h-[350px]">
            {/* Venue Listing section */}
            <VenueListing latlng={latlng} allSports={allSports} />

            {/* Activity Listing Section */}
            <ActivityList latlng={latlng} allSports={allSports} />

            {/* Marketing Promotion Banner */}
            <PromotionBanner />

            {/* Popular Sports Section */}
            <PopularSports />
         </section>

         {/* Corporate Partner Section */}
         {/* <section className="md:mx-12 mx-4 mt-14 xxl:mx-auto max-w-page md:py-[52px] py-6 bg-white md:rounded-3xl rounded-2xl">
            <PartnerWithUs />
         </section> */}

         {/* Spotlight Section */}
         <SpotLight spotLightBanners={homePageData?.marketingSpotlight?.journeys} />

         {/* Blog Section */}
         <section className="md:mx-12 mx-4 mt-14 xxl:mx-auto max-w-page md:py-12 py-6 bg-white md:rounded-3xl rounded-2xl">
            <Blogs />
         </section>

         {/* About Us */}
         <section className=" xxl:w-full md:mx-12 mx-4 mt-14 xxl:mx-auto max-w-page bg-white md:rounded-3xl rounded-2xl">
            <AboutUsHome />
         </section>
      </div>
   );
}

Home.getLayout = function getLayout(page) {
   return (
      <Layout hideLocation={true} isHomePage>{page}</Layout>
   )
}

export async function getServerSideProps(context) {
   const session = await getSession(context?.req, context?.res);
   const parsedSession = JSON.parse(JSON.stringify(session));
   let users = parsedSession.data || {};
   let allSports = await redisClient.mget(["allsports"], (err, reply) => {
      return reply;
   });

   return {
      props: {
         allSports: JSON.parse(allSports),
         users: users
      }
   }
}


