import Image from "next/image";
import React, { useEffect, useState } from "react";
import { getLocationFromLocalStorage } from "../../util/helperFunctions";
import { IO_API_SERVICE } from "../../services/ioApi";

const PromotionBanner = () => {
   const [banners, setBanners] = useState({});
   const [loading, setLoading] = useState(false);

   const storedLocation = getLocationFromLocalStorage();

   const getBanners = () => {
      setLoading(true);
      IO_API_SERVICE.getRedisBanners()
         .then(res => {
            setBanners(res.data[0]);
         })
         .catch(err => {
            console.error({ err });
         })
         .finally(() => {
            setLoading(false);
         });
   };

   useEffect(() => {
      getBanners();
   }, []);

   if (loading) {
      return <div className="shimmer w-full h-[154px] md:h-5" />;
   }

   if (banners.mobileBanner) {
      return (
         <div className="md:px-12 px-4 mt-8 overflow-hidden md:h-[154px] h-[174px]">
            <div className="relative h-[154px] md:hidden block w-full rounded-3xl overflow-hidden">
               <Image
                  src={banners?.mobileBanner}
                  alt={banners.description || "Promotion banner"}
                  layout="fill"
               />
            </div>
            <div className="relative h-[154px] hidden md:block w-full md:rounded-[52px] overflow-hidden">
               <Image
                  src={banners?.desktopBanner}
                  alt={banners.description || "Promotion banner"}
                  layout="fill"
               />
            </div>
         </div>
      );
   }

   return null;
};

export default PromotionBanner;
